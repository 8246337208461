import React from "react"
// import { Link } from "gatsby"
// import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import styles from "../styles/resume.module.scss"

const ResumePage = () => (
  <div className={styles.bg}>
    {/* <Layout> */}
      <div className={styles.spacer}></div>
      <section className={styles.resume}>
        <SEO title="Resume" />
        <div className={styles.work}>
          <h1>Resume</h1>
          {/* Jobs */}
          <div className={styles.job}>
            <div className={styles.title}>
              <h4>Oscar</h4>
              <p> Product Designer, Design Systems</p>
            </div>
            <p className={styles.date}>April 2022 – Present</p>
            <p className={styles.description}>
            Lead designer for Anatomy, Oscar's design system. Projects range from designing core foundations, formalizing standards for component documentation, defining processes for component/feature requests, documenting guidelines on how to contribute to Anatomy and work with the core team and much, much more. 
            </p>
          </div>
          <div className={styles.job}>
            <div className={styles.title}>
              <h4>PAX</h4>
              <p> Senior Product Designer</p>
            </div>
            <p className={styles.date}>August 2021 – December 2021</p>
            <p className={styles.description}>
            Designing experiences for the PAX mobile and web application for connected hardware devices. Responsibilities include design direction, product research, strategy, user interface design, user interaction design, motion design, visual communication and prototyping.
            </p>
          </div>
          <div className={styles.job}>
            <div className={styles.title}>
              <h4>Slalom</h4>
              <p>Product Designer</p>
            </div>
            <p className={styles.date}>August 2018 – April 2021</p>
            <p className={styles.description}>
              Product design consultant helping companies design and build
              digital products and experiences. I provide expertise with
              responsive web design, interaction design, user experience,
              usability, visual communication and front-end development. Clients
              I’ve worked for include Google, American Express and United Healthcare.
            </p>
          </div>
          <div className={styles.job}>
            <div className={styles.title}>
              <h4>Xilinx</h4>
              <p>UI/UX Designer & Developer</p>
            </div>
            <p className={styles.date}>February 2016 – August 2018</p>
            <p className={styles.description}>
              Designed and developed digital experiences for Xilinx.com.
              Designed and launched a template for product pages that would
              scale with growth, while streamlining processes for the team.
              Redesigned the training portal and curriculum templates.
              Redesigned and supported internal intranet. Helped to lead rebrand
              intiative focusing on visual identity design. Started a design
              system, focusing on creating a component library and foundational
              elements to help speed up the design and development of
              xilinx.com.
            </p>
          </div>
          <div className={styles.job}>
            <div className={styles.title}>
              <h4>Galileo</h4>
              <p>Lead Instructor & Curriculum Developer</p>
            </div>
            <p className={styles.date}>June 2015 – February 2016</p>
            <p className={styles.description}>
              Taught 4-8th graders how to design and develop video games and
              mobile applications while leading teacher assistants. Created and
              taught four curriculums: Intro to 3D Game Design, 3D Game Design
              Masters, Mobile App Creation and Mobile Game Design.
            </p>
          </div>
          <div className={styles.job}>
            <div className={styles.title}>
              <h4>UEGroup</h4>
              <p>UI/UX Designer (Intership)</p>
            </div>
            <p className={styles.date}>October 2014 – February 2015</p>
            <p className={styles.description}>
              Researched and designed game character configuration UI/UX
              concepts for the “Create a Class” system for Activision’s Call of
              Duty franchise. Designed and developed a prototype for UEGroups
              internal moodboard tool, YOUXEMOTIONS.
            </p>
          </div>
          <div className={styles.job}>
            <div className={styles.title}>
              <h4>University of California, Santa Cruz</h4>
              <p>Web Designer & Developer</p>
            </div>
            <p className={styles.date}>October 2013 – June 2014</p>
            <p className={styles.description}>
              Undergraduate researcher assisting Dr. Michael Strong and John
              Gargani on education research. Designed and developed two web
              applications to help conduct education based studies.
            </p>
          </div>
          <div className={styles.job}>
            <div className={styles.title}>
              <h4>Cubico Media Labs</h4>
              <p>Graphic Designer (Internship)</p>
            </div>
            <p className={styles.date}>June 2013 – September 2014</p>
            <p className={styles.description}>
              Designed graphics for digital content and apparel. Redesigned the
              e-commerce portion for the company’s website. Designed and
              developed a prototype template using Bootstrap.js.
            </p>
          </div>
        </div>
        <div className={styles.skills}>
          <div className={styles.university}>
            <h4>University</h4>
            <p>University of California, Santa Cruz</p>
            <p>Bachelors of Science: <br/>  Computer Science - Game Design</p>
            <p>September 2010 – June 2014</p>
          </div>
          <div className={styles.category}>
            <h4>Design</h4>
            <ul>
              <li>Web Design</li>
              <li>Responsive Design</li>
              <li>Interaction Design</li>
              <li>Motion Design</li>
              <li>Visual Design</li>
            </ul>
          </div>
          <div className={styles.category}>
            <h4>Tools</h4>
            <ul>
              <li>Sketch</li>
              <li>Figma</li>
              <li>Photoshop</li>
              <li>Illustrator</li>
              <li>After Effects</li>
            </ul>
          </div>
          <div className={styles.category}>
            <h4>Development</h4>
            <ul>
              <li>HTML</li>
              <li>CSS</li>
              <li>SASS</li>
              <li>Javascript</li>
              <li>React</li>
              <li>Gatsby</li>
              <li>Jekyll</li>
            </ul>
          </div>
          <div className={styles.category}>
            <h4>Research</h4>
            <ul>
              <li>Design Sprints</li>
              <li>Workshops</li>
              <li>User Testing</li>
              <li>Storyboarding</li>
            </ul>
          </div>
          <div className={styles.category}>
            <h4>Interests</h4>
            <ul>
              <li>Game Design</li>
              <li>Motion Design</li>
              <li>AR/VR Design</li>
              <li>Skateboarding</li>
              <li>Guitars</li>
            </ul>
          </div>
        </div>
      </section>
    {/* </Layout> */}
  </div>
)

export default ResumePage
